<template>
    <v-hover
        v-slot="{ hover }"
        open-delay="200"
      >
        <v-card
        :elevation="hover ? 16 : 0"
        :class="{ 'on-hover mt-n3' : hover }"
        class="mx-auto"
        @click="$emit('revisar')"
       
        >
            <v-img
                :src="imagen"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="350px"
                >
                <v-card-title v-text="nombre"></v-card-title>
            </v-img>
            <v-expand-transition>
            <div v-show="hover">
                <v-divider></v-divider>
                <!--
                <v-card-text>
                    {{descripcion}}
                </v-card-text>
                -->
                <v-card-actions>
                    <v-btn block color="indigo" text > Revisar </v-btn>
                </v-card-actions>
            </div>
            </v-expand-transition>
        </v-card>
    </v-hover>
</template>
<script>
export default {
    props: {
        imagen: {
            type: String,
            default: "https://picsum.photos/1920/1080?random"
        },
        nombre: {
            type: String,
            required: true,
        }

    },
    data(){
        return {

        }
    }
}
</script>
