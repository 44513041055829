<template>
    <EstructuraBasica class="mx-auto mt-n15">
        <template v-slot:main>
            <v-row>
               <v-col cols="6" sm="4" class="mx-auto" v-for="(componente,index) in componentes" :key="index" >
                   <CardAdmin 
                        :nombre="componente.nombre"
                        :imagen="componente.imagen"
                        @revisar="$router.push({name: componente.ruta })"
                        
                   />
               </v-col>
           </v-row>
        </template>
    </EstructuraBasica>
</template>
<script>
import CardAdmin from '../../components/admin/CardActions.vue'
export default {
    components: {
        CardAdmin,
    },
    data(){
        return {
            componentes: [
                {
                    nombre: 'Areas',
                    ruta: "crud_sectores",
                    imagen: require('../../assets/sectores.png'),
                },
                {
                    imagen: require('../../assets/buscar.png'),
                    nombre: 'Documentos',
                    ruta: "crud_documentos"
                },
                {
                    imagen: require('../../assets/workflows.png'),
                    nombre: 'Workflows',
                    ruta: "crud_workflows"
                },
                {
                    imagen: require('../../assets/buscar.png'),
                    nombre: 'Busquedas',
                    ruta: "crud_validaciones"
                },
                {
                    imagen: require('../../assets/sectores.png'),
                    nombre: 'Reportes documentales',
                    ruta: "reporte_documental"
                },
                {
                    imagen: require('../../assets/sectores.png'),
                    nombre: 'Reportes de flujo',
                    ruta: "reporte_workflow"
                },
                
            ]
        }
    }
}
</script>